"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBoostStripeAccountKey = exports.formatCurrency = exports.calculatePriceAfterPromotion = exports.calculatePerMonthPrice = exports.calculateDiscount = void 0;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 *
 * @param {number} current - The current price
 * @param {number} original - The original price
 * @returns {number} discount percentage
 */
var calculateDiscount = exports.calculateDiscount = function calculateDiscount(current, original) {
  return Math.round((1 - current / original) * 100);
};

/**
 *
 * @param {string} selectedTerm - The selected term
 * @param {number} price - The current price
 * @param {number} originalPrice - The original price
 * @returns {{perMonthPrice: number, perMonthOriginalPrice: number}} perMonthPrice - The price per month
 */
var calculatePerMonthPrice = exports.calculatePerMonthPrice = function calculatePerMonthPrice(selectedTerm, price, originalPrice) {
  var perMonthPrice = selectedTerm === "yearly" ? price / 12 : price;
  var perMonthOriginalPrice = selectedTerm === "yearly" ? originalPrice / 12 : originalPrice;
  return {
    perMonthPrice: perMonthPrice,
    perMonthOriginalPrice: perMonthOriginalPrice
  };
};
var calculatePriceAfterPromotion = exports.calculatePriceAfterPromotion = function calculatePriceAfterPromotion(price) {
  var percentOff = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var credit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  // promotion logic is to apply percentage discount first then fixed credit
  // min payment amount is $0.01 for sanity
  // using Math.round(price * 100)/100 to get the same price as stripe
  // ex: when applying 50% off on 999.99, the end result should be 499.99

  // handle decimals as an integer to avoid node decimal issues -> eg: 69.99 * 100 = 6998.999999999999
  var priceInCents = Math.round(price * 100);
  var creditInCents = Math.round(credit * 100);
  var roundedDiscountAmount = Math.round(priceInCents * percentOff / 100);
  var finalPriceInCents = priceInCents - roundedDiscountAmount - creditInCents;

  // convert price * 100 as integer back to decimal
  var finalPrice = Math.max(finalPriceInCents / 100, 0.01);
  // avoid floating point precision issues
  return Math.round(finalPrice * 100) / 100;
};
var formatCurrency = exports.formatCurrency = function formatCurrency(value, options) {
  return value === null || value === void 0 ? void 0 : value.toLocaleString("en-US", _objectSpread(_objectSpread({}, (options === null || options === void 0 ? void 0 : options.currencyDisplay) !== "none" && {
    style: "currency",
    currency: "USD",
    currencyDisplay: (options === null || options === void 0 ? void 0 : options.currencyDisplay) || "symbol"
  }), {}, {
    maximumFractionDigits: (options === null || options === void 0 ? void 0 : options.digits) || 0,
    minimumFractionDigits: (options === null || options === void 0 ? void 0 : options.digits) || 0
  }));
};

/*
* util function to grab the appriopriate stripe env key for boost stripe setup.
* can force which key to use by passing in which stripe account.
* 09/18 - default to stripe v1 due to stripe incident
*
*/
var getBoostStripeAccountKey = exports.getBoostStripeAccountKey = function getBoostStripeAccountKey() {
  var stripeAccount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "BOOST";
  if (stripeAccount !== "BOOST_V2") {
    return process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  }
  return process.env.REACT_APP_STRIPE_V2_PUBLIC_KEY;
};